import PropTypes from "prop-types";
import React from "react";

import SmallButton from "../button/small-button/SmallButton";
import addI18nContextConsumer from "../i18n/addI18nContextConsumer";
import i18n from "./RequestDemo.i18n.json";
import styles from "./RequestDemo.module.scss";

function RequestDemo({ bg, idForGTM, strings, locale }) {
  const verySmallScreen =
    typeof window !== "undefined" &&
    !window.matchMedia("(min-width: 640px)").matches;
  return (
    <div className={styles["container"]} style={{ backgroundImage: bg }}>
      <div className={styles["content"]}>
        <div className={styles["body"]}>
          <div className={styles["title"]}>{strings["title"]}</div>
          <div className={styles["label"]}>{strings["label"]}</div>
          <div className={styles["button"]}>
            <SmallButton
              background="#ffd8d1"
              classNameForGTM={
                idForGTM
                  ? `Link-${idForGTM}-Contact`
                  : "Link-RequestDemo-Contact"
              }
              href={`/${locale}/contact`}
              label={strings["ask_demo"]}
              noBorder
              theme={
                verySmallScreen
                  ? SmallButton.THEMES.XSMALL
                  : SmallButton.THEMES.SIMPLE
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

RequestDemo.propTypes = {
  /*eslint-disable react/forbid-prop-types*/
  bg: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
  idForGTM: PropTypes.string,
};

RequestDemo.defaultProps = {
  idForGTM: void 0,
};

export default addI18nContextConsumer(i18n)(RequestDemo);
