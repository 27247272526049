/* eslint-disable */
import React from "react";

export default {
  en: {
    quote:
      "They are people you can talk to, who will be able to carefully listen to your challenges and help you solve them.",
    author: "Martin d’Allens, CTO",
    sector: "Roadbook platform for bikers",
    interviewees: ["Martin d’Allens, Chief Technical Officer"],
    service: "Dynamic & Static Maps, Geocoding, Routing",
    geo: "France",
    blocks: [
      {
        title: "Liberty Rider",
        description: (
          <p>
            <a
              href="https://liberty-rider.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Liberty Rider
            </a>{" "}
            is the rescue app for anyone owning a motorcycle. It can detect an
            accident and simultaneously alert emergency services, while sending
            along critical details (like the geolocation of the accident). One
            can also find roadbooks, for all kinds of ride. In short, it is a
            "must have" made in Toulouse. At the heart of this project, CTO
            Martin d'Allens covers all the technical aspects of the application.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "Liberty Rider & Jawg",
        description: (
          <span>
            Liberty Rider exposes a whole variety of services, including maps,
            static maps in newsfeeds or dynamic maps displaying the details of a
            trip. Search services are also provided for users to find specific
            geographic locations. At LR,{" "}
            <q>we use Jawg in most of our services</q>.
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            When Jawg reached out to Liberty Rider, some of the employees of
            Jawg had already been using the app just thought it would be nice to
            chat. Following up with a few calls, Jawg was able to answer all
            questions from Martin and his team{" "}
            <q>without being limited to their own scope</q>.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Having a lot of map traffic, it was obvious to Martin that changing
            their provider would not be a risk-free operation, especially for a
            startup. Reassured by a <q>very responsive</q> support, it was also
            the fact that Jawg teams demonstrated great flexibility and a
            progressive pricing, which allowed the startup to make the leap in
            complete peace of mind.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <q>
            They are people you can talk to, who will be able to carefully
            listen to your challenges and help you solve them. They are not a
            Big company where we would just have been another client among many
            others
          </q>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Over time, LR's mapping needs have become more diversified, and Jawg
            kept on advising them and releasing the right services meeting those
            needs, and <q>that is interesting</q>. What does Martin appreciate
            most? <q>I do not need to worry about it. It just works</q>.
          </span>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Maps, Brillant, Fun",
      },
    ],
  },
  fr: {
    quote:
      "C’est des gens avec qui on va pouvoir parler, qui vont pouvoir prendre tes problèmes et t’aider à les résoudre.",
    author: "Martin d’Allens, CTO",
    sector: "Sécurité • Roadbook",
    interviewees: ["Martin d’Allens, Chief Technical Officer"],
    service: "Dynamic & Static Maps, Geocoding, Routing",
    geo: "France",
    blocks: [
      {
        title: "Liberty Rider",
        description: (
          <p>
            Grand chouchou des médias qui lui ont consacré de nombreux
            reportages,{" "}
            <a
              href="https://liberty-rider.com/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Liberty Rider
            </a>{" "}
            est l'appli "made in Toulouse" qui est devenue l'outil indispensable
            des motards. Si elle est remplie de roadbooks et d'outils adaptés à
            chacun, c'est surtout sa capacité à détecter une chute et alerter
            automatiquement les secours qui en a fait un must-have. Martin
            D'Allens, co-fondateur et CTO nous confie les enjeux de LR vus de
            l'intérieur.
          </p>
        ),
        img: "clientLogo",
      },
      {
        title: "Liberty Rider & Jawg",
        description: (
          <span>
            Liberty Rider c’est une grande variété de services, notamment
            cartographiques, que ce soit des cartes statiques dans les flux de
            news ou les cartes dynamiques qui affiche les détails d’un trajet.
            Les utilisateurs utilisent également un service de recherche pour
            trouver des positions géographiques spécifiques. Chez LR,{" "}
            <q>&#160;dans la plupart de nos services on utilise Jawg&#160;</q>.
          </span>
        ),
        img: "usageHighlight",
      },
      {
        title: "",
        description: (
          <span>
            La rencontre Liberty Rider et Jawg, c’est avant tout une rencontre
            entre motards qui avaient envie de pousser une solution française.
            L’aventure commence suite à une première étape de conseil par Jawg,
            qui a su répondre aux questions de Martin et son équipe{" "}
            <q>
              &#160;sans pour autant se limiter à leur propre périmètre&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Avec beaucoup de requêtes sur les cartes, il était évident pour
            Martin que changer de fournisseur n’était pas une opération sans
            risque, surtout pour une startup. Rassuré par un support{" "}
            <q>&#160;très réactif&#160;</q>, c’est aussi un basculement de
            charge progressif accompagné d’une tarification adaptée, qui a
            permis à la startup de sauter le pas en toute sérénité.
          </span>
        ),
      },
      {
        title: "",
        description: (
          <q>
            C’est des gens avec qui on va pouvoir parler, qui vont pouvoir
            prendre tes problèmes et t’aider à les résoudre et pas une boîte
            américaine pour laquelle on serait qu’un contrat parmi tant d’autres
            et un petit contrat donc auquel ils font pas attention.
          </q>
        ),
      },
      {
        title: "",
        description: (
          <span>
            Avec le temps, les besoins cartographiques de LR se sont
            diversifiés, et Jawg a su conseiller et mettre en place en face des
            services correspondants à ces besoins, et{" "}
            <q>&#160;ça c’est intéressant&#160;</q>. Ce qui plaît le plus à
            Martin chez Jawg ?{" "}
            <q>
              &#160;C’est que je ne m’en préoccupe pas et que ça marche&#160;
            </q>
            .
          </span>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Maps, brillant, fun",
      },
    ],
  },
};
