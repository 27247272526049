import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import i18n from "./Header.i18n.json";
import styles from "./Header.module.scss";

function Header({ strings, img, logo, sector, interviewees, service, geo }) {
  return (
    <div className={styles["container"]}>
      <div className={styles["body"]}>
        <div className={styles["image"]}>
          <GatsbyImage image={img} />
        </div>
        <div className={styles["info"]}>
          <h1>{strings["title"]}</h1>
          <div className={styles["logo"]}>
            <GatsbyImage image={logo} />
          </div>
          <div className={styles["list"]}>
            <div>
              <span className={styles["highlight"]}>{strings["sector"]} </span>
              {sector}
            </div>
            <div>
              <span className={styles["highlight"]}>
                {interviewees.length > 1
                  ? strings["persons"]
                  : strings["person"]}
              </span>{" "}
              {interviewees.join(strings["intervieweesJoin"])}
            </div>
            <div>
              <span className={styles["highlight"]}>{strings["service"]} </span>
              {service}
            </div>
            <div>
              <span className={styles["highlight"]}>{strings["geo"]} </span>
              {geo}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  geo: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  interviewees: PropTypes.arrayOf(PropTypes.string).isRequired,
  logo: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Header);
