import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import ClientCard from "../clients/client-card/ClientCard";
import addI18nContextConsumer from "../i18n/addI18nContextConsumer";
import RequestDemo from "../request-demo/RequestDemo";
import i18n from "./Client.i18n.json";
import styles from "./Client.module.scss";
import Header from "./header/Header";

export const getHeaderAndText = ({ strings, staticQuery }, opts = {}) => {
  const header = {
    img: getImage(staticQuery.clientCover),
    logo: getImage(staticQuery.clientLogoDark),
    sector: strings["sector"],
    interviewees: strings["interviewees"],
    service: strings["service"],
    geo: strings["geo"],
  };
  const text = strings["blocks"].map((b, index) =>
    Object.assign({}, b, {
      img: b.img && (opts[b.img] || getImage(staticQuery[b.img])),
      isALogo: b.img === "clientLogo",
      isImageAnSVG: b.img && !!opts[b.img],
    })
  );
  return { header, text, clientCover: staticQuery.clientCover };
};

function Client({ strings, text, quote, author, nav, header }) {
  const data = useStaticQuery(graphql`
    query {
      imageBGMaps: file(relativePath: { eq: "bg_maps.jpeg" }) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  return (
    <>
      <Header
        geo={header.geo}
        img={header.img}
        interviewees={header.interviewees}
        logo={header.logo}
        sector={header.sector}
        service={header.service}
      />
      <div className={styles["container"]}>
        {text.map(({ title, description, img, isALogo, isImageAnSVG }) => (
          <>
            <div className={styles["block"]}>
              <div className={styles["title"]}>{title}</div>
              <p>{description}</p>
            </div>
            {img && (
              <div className={styles["image_container"]}>
                <div
                  className={classNames(styles["image"], {
                    [styles["logo"]]: isALogo,
                  })}
                >
                  {isImageAnSVG ? (
                    <img alt="" src={img} />
                  ) : (
                    <GatsbyImage image={img} />
                  )}
                </div>
              </div>
            )}
          </>
        ))}
        <div className={styles["quote"]}>
          <div className={styles["text"]}>
            <q>{quote}</q>
          </div>
          <div className={styles["autor"]}>{author}</div>
        </div>
      </div>
      <RequestDemo bg={data.imageBGMaps} />
      <div className={styles["nav"]}>
        <div className={styles["label"]}>{strings["discover"]}</div>
        <div className={styles["cardsContainer"]}>
          <div className={styles["cards"]}>
            {nav.map(({ key, link, img }) => (
              <ClientCard description={strings[key]} link={link} logo={img} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

Client.propTypes = {
  author: PropTypes.string.isRequired,
  header: PropTypes.exact({
    img: PropTypes.string,
    logo: PropTypes.string,
    sector: PropTypes.string,
    interviewees: PropTypes.array,
    service: PropTypes.string,
    geo: PropTypes.string,
  }).isRequired,
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      img: PropTypes.string,
      isALogo: PropTypes.bool,
      isImageAnSVG: PropTypes.bool,
      title: PropTypes.string,
    })
  ).isRequired,
  quote: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
  /*eslint-disable react/forbid-prop-types*/
  text: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Client);
