import { graphql, useStaticQuery } from "gatsby";

export const useQuery = () =>
  useStaticQuery(graphql`
    query {
      clientLogo: file(
        relativePath: {
          eq: "Logos-client/Largeur variable/Color/LibertyRider.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      clientCover: file(
        relativePath: { eq: "interview/LibertyRider-top.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      usageHighlight: file(
        relativePath: { eq: "interview/LibertyRider-middle.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 2000, formats: [AUTO, WEBP, AVIF])
        }
      }
      clientLogoDark: file(
        relativePath: {
          eq: "Logos-client/Largeur variable/Dark/LibertyRider.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 250, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
